import React, { lazy } from "react";

const CollaborationMain = lazy(() => import("@/pages/Collaboration/CollaborationMain"));
const CollaborationList = lazy(() => import("@/pages/Collaboration/CollaborationList"));
const CollaborationDetails = lazy(() => import("@/pages/Collaboration/CollaborationDetails"));
const UnauthCollabDetails = lazy(() => import("@/pages/Collaboration/UnauthCollabDetails"));
const CollaborationPosts = lazy(() => import("@/pages/Collaboration/YourPosts"));
const CollaborationApplicants = lazy(() => import("@/pages/Collaboration/PostDetails"));
const YourCollabs = lazy(() => import("@/pages/Collaboration/YourCollabs"));

const collaborationRoutes = [
  { path: "/collaboration", element: <CollaborationMain /> },
  { path: "/collaboration/live", element: <CollaborationList /> },
  { path: "/collaboration/:id", element: <CollaborationDetails /> },
  // { path: "/unauth-collab/:id", element: <UnauthCollabDetails /> },
  { path: "/collaboration/posts", element: <CollaborationPosts /> },
  { path: "/collaboration/collabs", element: <YourCollabs /> },
  { path: "/collaboration/:id/applicants", element: <CollaborationApplicants /> },
];

export default collaborationRoutes;
