import React, { useState, createContext } from "react";
import { HashRouter as Router } from "react-router-dom";
import AppRoutes from "./Route";
import { Toaster } from "sonner";
import { GoogleOAuthProvider } from '@react-oauth/google';
export const AnnouncementContext = createContext();

const App = () => {
  const [showAnnouncement, setShowAnnouncement] = useState(true);

  const handleAnnouncementClose = () => {
    setShowAnnouncement(false);
    // localStorage.setItem("hasVisitedHomePage", true);
  };

  return (
    <Router>
      <div className="flex h-screen flex-col">
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <AnnouncementContext.Provider
            value={{ showAnnouncement, handleAnnouncementClose }}
          >
            <AppRoutes />
            <Toaster position="top-right" richColors />
          </AnnouncementContext.Provider>
        </GoogleOAuthProvider>
      </div>
    </Router>
  );
};

export default App;
