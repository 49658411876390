import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  IoHomeOutline,
  IoHome,
  IoWalletOutline,
  IoWallet,
  IoLogOutOutline,
  IoLogOut,
  IoLogInOutline,
} from "react-icons/io5";
import {
  PiHandshakeLight,
  PiHandshakeFill,
  PiUserCircleFill,
  PiUserCircle,
} from "react-icons/pi";

import { HiHandRaised, HiOutlineHandRaised } from "react-icons/hi2";
import {
  FaCircleQuestion,
  FaQuestion,
  FaReceipt,
  FaCircleInfo,
} from "react-icons/fa6";
import {
  MdOutlinePhoneInTalk,
  MdPhoneInTalk,
  MdOutlineChat,
  MdChat,
  MdMarkUnreadChatAlt,
  MdOutlineMarkUnreadChatAlt,
} from "react-icons/md";
import { RiAuctionFill, RiAuctionLine } from "react-icons/ri";
import {
  ClipboardCheck,
  ClipboardList,
  ReceiptText,
  Ticket,
} from "lucide-react";
import { BsTelephone, BsTelephoneFill, BsTicketFill } from "react-icons/bs";
import {
  RiUserSearchFill,
  RiUserSearchLine,
  RiAdvertisementLine,
  RiAdvertisementFill,RiTeamLine,RiTeamFill
} from "react-icons/ri";
import { FaUserCheck, FaRegUser } from "react-icons/fa";
import { BsClipboardCheckFill } from "react-icons/bs";
import { TbCategory, TbCategoryFilled,TbCurrencyRupee } from "react-icons/tb";
import { CiAt } from "react-icons/ci";
import { FaAt } from "react-icons/fa";
import useAuth from "@/redux/dispatch/useAuth";
import axiosInstance from "@/api/axios";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState("home");
  const [hasUnread, setHasUnread] = useState(false);
  const navigate = useNavigate();
  const { logoutUser, auth, isAdmin, isAuthenticated } = useAuth();
  const id = auth.user?.id;
  const isConsultant = auth.user?.consultant_id ? true : false;
  const [stateUrl, setStateUrl] = useState();

  const adminMenu = [
    {
      name: "Admin",
      icon: <FaRegUser className="text-2xl" />,
      activeIcon: <FaUserCheck className="text-2xl" />,
      link: "/admin",
      toURL: "/admin",
      type: "admin",
    },
    {
      name: "Users",
      icon: <RiUserSearchLine className="text-2xl" />,
      activeIcon: <RiUserSearchFill className="text-2xl" />,
      link: "/admin/users",
      toURL: "/admin/users",
      type: "admin",
    },
    {
      name: "Social Verify",
      icon: <CiAt className="text-2xl" />,
      activeIcon: <FaAt className="text-2xl" />,
      link: "/admin/socials",
      toURL: "/admin/socials",
      type: "admin",
    },
    {
      name: "Consultants Serivces",
      icon: <PiHandshakeLight className="text-2xl" />,
      activeIcon: <PiHandshakeFill className="text-2xl" />,
      link: "/admin/consultant",
      toURL: "/admin/consultant",
      type: "admin",
    },
    {
      name: "Bids",
      icon: <HiOutlineHandRaised className="text-2xl" />,
      activeIcon: <HiHandRaised className="text-2xl" />,
      link: "/admin/bids",
      toURL: "/admin/bids",
      type: "admin",
    },
    // {
    //   name: "Chat Settlements",
    //   icon: <PiHandshakeLight className="text-2xl" />,
    //   activeIcon: <PiHandshakeFill className="text-2xl" />,
    //   link: "/admin/settlement/chat",
    //   toURL: "/admin/settlement/chat",
    //   type: "admin",
    // },
    {
      name: "Auction Settlements",
      icon: <PiHandshakeLight className="text-2xl" />,
      activeIcon: <PiHandshakeFill className="text-2xl" />,
      link: "/admin/settlement/auctions",
      toURL: "/admin/settlement/auctions",
      type: "admin",
    },
    {
      name: "Earnings Settlements",
      icon: <TbCurrencyRupee className="text-2xl" />,
      activeIcon: <TbCurrencyRupee className="text-2xl" />,
      link: "/admin/settlement/earnings",
      toURL: "/admin/settlement/earnings",
      type: "admin",
    },
    {
      name: "Consultation Settlements",
      icon: <PiHandshakeLight className="text-2xl" />,
      activeIcon: <PiHandshakeFill className="text-2xl" />,
      link: "/admin/settlement/adminConsultationSettlements",
      toURL: "/admin/settlement/adminConsultationSettlements",
      type: "admin",
    },
    {
      name: "KYC Verify",
      icon: <FaUserCheck className="text-2xl" />,
      activeIcon: <FaRegUser className="text-2xl" />,
      link: "/admin/kyc-home",
      toURL: "/admin/kyc-home",
      type: "admin",
    },
    // {
    //   name: "Consultant",
    //   icon: <BsTelephone className="text-2xl" />,
    //   activeIcon: <BsTelephoneFill className="text-2xl" />,
    //   link: "/admin/consultant",
    //   toURL: "/admin/consultant",
    //   type: "admin",
    // },
    {
      name: "Tickets",
      icon: <BsTicketFill className="text-2xl" />,
      activeIcon: <Ticket className="text-2xl" />,
      link: "/admin/tickets",
      toURL: "/admin/tickets",
      type: "admin",
    },
    {
      name: "Approved Auction",
      icon: <ClipboardCheck className="text-2xl" />,
      activeIcon: <BsClipboardCheckFill className="text-2xl" />,
      link: "/admin/auctions/approved",
      toURL: "/admin/auctions/approved",
      type: "admin",
    },
    {
      name: "Pending Auction",
      icon: <ClipboardList className="text-2xl" />,
      activeIcon: <ClipboardList className="text-2xl" />,
      link: "/admin/auctions",
      toURL: "/admin/auctions",
      type: "admin",
    },
    {
      name: "Category Auction",
      icon: <TbCategory className="text-2xl" />,
      activeIcon: <TbCategoryFilled className="text-2xl" />,
      link: "/admin/auctions/category",
      toURL: "/admin/auctions/category",
      type: "admin",
    },
    {
      name: "Manage FAQ",
      icon: <FaQuestion className="text-2xl" />,
      activeIcon: <FaCircleQuestion className="text-2xl" />,
      link: "/admin/manage-faqs",
      toURL: "/admin/manage-faqs",
      type: "admin",
    },
    {
      name: "Ads Manager",
      icon: <RiAdvertisementLine className="text-2xl" />,
      activeIcon: <RiAdvertisementFill className="text-2xl" />,
      link: "/admin/ad-manager",
      toURL: "/admin/ad-manager",
      type: "admin",
    },
  ];

  const menu = [
    {
      name: "Home",
      icon: <IoHomeOutline className="text-2xl" />,
      activeIcon: <IoHome className="text-2xl" />,
      link: "/",
      toURL: "/",
      type: "public",
    },
    {
      name: "Profile",
      icon: auth?.user?.profileImage ? (
        <img
          src={auth?.user?.profileImage}
          alt="profile"
          className="h-6 w-6 rounded-full object-cover"
        />
      ) : (
        <PiUserCircle className="text-3xl" />
      ),
      activeIcon: auth?.user?.profileImage ? (
        <img
          src={auth.user.profileImage}
          alt="profile"
          className="h-6 w-6 rounded-full object-cover"
        />
      ) : (
        <PiUserCircleFill className="text-3xl" />
      ),
      link: "/profile",
      toURL: "/profile/" + auth?.user?.userName,
      type: "private",
    },
    {
      name: "Nob Chat",
      icon: hasUnread ? (
        <MdOutlineMarkUnreadChatAlt className="text-2xl" />
      ) : (
        <MdOutlineChat className="text-2xl" />
      ),
      activeIcon: hasUnread ? (
        <MdMarkUnreadChatAlt className="text-2xl" />
      ) : (
        <MdChat className="text-2xl" />
      ),
      link: "/chat",
      toURL: "/chat/private",
      type: "private",
    },
    {
      name: "Add Funds",
      icon: <IoWalletOutline className="text-2xl" />,
      activeIcon: <IoWallet className="text-2xl" />,
      link: "/trading",
      toURL: "/trading",
      type: "private",
    },
    {
      name: "Auction Center",
      icon: <RiAuctionLine className="text-2xl" />,
      activeIcon: <RiAuctionFill className="text-2xl" />,
      link: "/center",
      toURL: "/center",
      type: "private",
    },
    {
      name: "Collaborations",
      icon: <RiTeamLine className="text-2xl" />,
      activeIcon: <RiTeamFill className="text-2xl" />,
      link: "/collaboration",
      toURL: "/collaboration",
      type: "private",
    },
    
    // {
    //   name: "Affiliate & Earn",
    //   icon: <TbCurrencyRupee className="text-2xl" />,
    //   activeIcon: <TbTransactionRupee className="text-2xl" />,
    //   link: "/affiliation-center",
    //   toURL: "/affiliation-center",
    //   type: "private",
    // },
    // {
    //   name: "Assist & Earn",
    //   icon: <TbCurrencyRupee className="text-2xl" />,
    //   activeIcon: <TbTransactionRupee className="text-2xl" />,
    //   link: "/assist-center",
    //   toURL: "/assist-center",
    //   type: "private",
    // },
    // {
    //   name: "Ad Manager",
    //   icon: <RiAdvertisementLine className="text-2xl" />,
    //   activeIcon: <RiAdvertisementFill className="text-2xl" />,
    //   link: "/ad-manager",
    //   toURL: "/ad-manager",
    //   type: "private",
    // },

    // {
    //  name: "Direct Sell",
    //  icon: <img src={sellIcon} alt="sell icon" className="h-6 w-6" />,
    //  activeIcon: <img src={sellIcon} alt="sell icon" className="h-6 w-6" />,
    //  link: "/direct-sell",
    //  toURL: "/direct-sell",
    //  type: "private",
    // },

    {
      name: "Consultation Manager",
      icon: <BsTelephone className="text-2xl" />,
      activeIcon: <BsTelephoneFill className="text-2xl" />,
      link: "/consultation//",
      toURL: "/consultation//",
      type: "consultant",
    },
    {
      name: "Settlements",
      icon: <PiHandshakeLight className="text-2xl" />,
      activeIcon: <PiHandshakeFill className="text-2xl" />,
      link: "/settlements-center",
      toURL: "/settlements-center",
      type: "private",
    },
    {
      name: "Earnings",
      icon: <TbCurrencyRupee className="text-2xl" />,
      activeIcon: <TbCurrencyRupee className="text-2xl" />,
      link: "/earnings",
      toURL: "/earnings",
      type: "private",
    },

    ...adminMenu,
    {
      name: "About Us",
      icon: <FaCircleInfo className="text-2xl" />,
      activeIcon: <FaCircleInfo className="text-2xl" />,
      link: "/about",
      toURL: "/about",
      type: "public",
    },

    {
      name: "Support",
      icon: <MdOutlinePhoneInTalk className="text-2xl" />,
      activeIcon: <MdPhoneInTalk className="text-2xl" />,
      link: "/support",
      toURL: "/support",
      type: "public",
    },
    {
      name: "Terms & Privacy",
      icon: <ReceiptText className="text-2xl" />,
      activeIcon: <FaReceipt className="text-2xl" />,
      link: "/terms-and-policies",
      toURL: "/terms-and-policies",
      type: "public",
    },
  ];

  useEffect(() => {
    const loc=window.location.href;
    const ver= loc.indexOf('ownlyfashions');
    if(ver!==-1){
      setStateUrl(true);
    }
    const fetchChatHistory = async () => {
      try {
        if (id) {
          const res = await axiosInstance.get(`/chat/private/unread`);
          const hasUnread = res.data;
          setHasUnread(hasUnread);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchChatHistory();
    if (auth.token) {
      const eventSource = new EventSource(
        axiosInstance.getUri() + "chat/private/events?token=" + auth.token,
      );

      eventSource.onmessage = function (event) {
        const eventData = JSON.parse(event.data);
        if (
          eventData.type === "new_message" ||
          eventData.type === "read_message"
        ) {
          setHasUnread(eventData.data.unread);
        }
      };

      return () => {
        eventSource.close();
      };
    }
  }, [id, activeItem]);

  // console.log(auth);

  return (
    !stateUrl && <>
      <div
        className={`${isOpen ? "w-full" : "w-fit"} fixed left-0 top-0 z-30 h-screen overflow-y-auto overflow-x-hidden bg-primary/30 transition-all duration-300`}
        onClick={() => isOpen && toggleSidebar()}
      ></div>
      <div className="relative lg:min-w-[16%]">
        <div
          className={`fixed z-30 flex h-full max-h-[94%] transform flex-col justify-between border-r border-gray-300 bg-white p-4 text-center shadow-md transition-transform duration-300 sm:static sm:h-full sm:max-h-full ${
            isOpen ? "translate-x-0" : "-translate-x-full"
          } overflow-y-auto sm:w-36 sm:translate-x-0 lg:w-full`}
        >
          <div className="flex flex-grow flex-col space-y-8 p-2">
            {menu.map((item, index) => {
              const { name, icon, activeIcon, toURL, link, type } = item;
              let isActivated = false;

              if (
                location.pathname.split("/")[1] === link.split("/")[1] &&
                location.pathname.split("/")[2] === link.split("/")[2] &&
                location.pathname.split("/")[3] === link.split("/")[3] &&
                isAdmin
              ) {
                isActivated = true;
              } else if (
                location.pathname.split("/")[1] === link.split("/")[1] &&
                !isAdmin
              ) {
                isActivated = true;
              }

              if (type === "private" && !isAuthenticated) return null;
              if (type === "private" && isAdmin) return null;
              if (type === "admin" && !isAdmin) return null;
              if (type === "consultant" && !isConsultant) return null;

              return (
                <div
                  key={index}
                  className={`flex cursor-pointer sm:flex-col items-center  gap-4 text-sm lg:flex-row lg:justify-start lg:gap-4 lg:text-base ${
                    isActivated ? "font-bold" : "hover:font-bold"
                  }`}
                  onClick={() => navigate(toURL)}
                >
                  {isActivated ? activeIcon : icon}
                  <span>{name}</span>
                </div>
              );
            })}
          </div>

          <div className="flex flex-col space-y-4 pb-5 sm:pb-0">
            {isAuthenticated ? (
              <div
                className={`mt-10 flex cursor-pointer flex-col items-center justify-center gap-1 text-sm lg:flex-row lg:justify-start lg:gap-4 lg:text-base ${
                  activeItem === "logout" ? "font-bold" : "hover:font-bold"
                }`}
                onClick={() => {
                  setActiveItem("logout");
                  logoutUser();
                  navigate("/login");
                }}
              >
                {activeItem === "logout" ? (
                  <IoLogOut className="text-2xl" />
                ) : (
                  <IoLogOutOutline className="text-2xl" />
                )}
                <span>Logout</span>
              </div>
            ) : (
              <div
                className={`flex cursor-pointer flex-col items-center justify-center gap-1 text-sm lg:flex-row lg:justify-start lg:gap-4 lg:text-base ${
                  activeItem === "login" ? "font-bold" : "hover:font-bold"
                }`}
                onClick={() => {
                  setActiveItem("login");
                  navigate(
                    "/login",
                    { state: { from: location.pathname } },
                    { replace: true },
                  );
                }}
              >
                <IoLogInOutline className="text-2xl" />
                <span>Login</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
