import React, { lazy } from "react";

// Lazy load the profile and settings components
const ProfileSection = lazy(() => import("@/pages/Profile/ProfileSection"));
const ProfileEdit = lazy(() => import("@/pages/Profile/ProfileEdit"));
const ProfileUpdate = lazy(() => import("@/pages/Profile/ProfileUpdate"));
const Settings = lazy(() => import("@/pages/Settings/Settings"));
const NobChat = lazy(() => import("@/pages/Settings/NobChat"));
const LiveChat = lazy(() => import("@/pages/Settings/LiveChat"));
const ProfileVerification = lazy(
  () => import("@/pages/Settings/ProfileVerification"),
);
const Kyc = lazy(() => import("@/pages/Kyc/Kyc"));

const profileAndSettingsRoutes = [
  { path: "/profile/:username", element: <ProfileSection /> },
  { path: "/profile/edit", element: <ProfileEdit /> },
  { path: "/update", element: <ProfileUpdate /> },
  { path: "/settings", element: <Settings /> },
  { path: "/settings/nobchat", element: <NobChat /> },
  { path: "/settings/livechat", element: <LiveChat /> },
  { path: "/settings/social", element: <ProfileVerification /> },
  { path: "/settings/kyc", element: <Kyc /> },
];

export default profileAndSettingsRoutes;
