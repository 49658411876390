import React, { lazy } from "react";

// Lazy load the private chat component
const Subscription = lazy(() => import("@/pages/Subscription/SubscriptionMain"));

const chatRoutes = [
  { path: "/ownlyfashions/subscription", element: <Subscription /> },
];

export default chatRoutes;
